import React from "react"
import Helmet from "react-helmet"
import "./layout.css"
import { FixedGlobalStyle, ThemeGlobalStyle, ThemeProvider } from "@/theme"
import { ScreenClassProvider } from "react-grid-system"
// import CountryPopupModal from "@/next-containers/CountryPopup/CountryPopupModal" // usage TBC
import { OneLinkProvider } from "@/components/hooks/useOneLink"
import { GeoCountryProvider } from "@/utils/useGeoCountry"
import { Script } from "gatsby"
import { LazyMotion, domAnimation } from "framer-motion"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { FirebaseProvider } from "@/utils/firebase"

const queryClient = new QueryClient()

export const LayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <FirebaseProvider>
      <Script src="/lib/gt.js" />
      <Script src="/lib/onelink-smart-script-latest.js" />
      <Script src="https://www.google.com/recaptcha/api.js" async defer />
      <Helmet>
        <meta
          name="google-site-verification"
          content="ZsldpD3RCgz2ntLBhPvWKAsL5uFtgUcxNuP5U0HDNe8"
        />
        <meta content="49b8ab40abd47d4c" name="yandex-verification" />
        <meta
          content="0a98bc7058ecaa15f2e6a2e01e61a09e"
          name="p:domain_verify"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,minimum-scale=1,maximum-scale=5"
        />
        <body className="fs-unmask" />
      </Helmet>
      <FixedGlobalStyle />

      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ScreenClassProvider>
            <OneLinkProvider>
              <GeoCountryProvider>
                <ThemeGlobalStyle />
                <LazyMotion features={domAnimation} strict>
                  {children}
                </LazyMotion>
              </GeoCountryProvider>
            </OneLinkProvider>
          </ScreenClassProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </FirebaseProvider>
  )
}
