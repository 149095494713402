import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on BR site, only show pt-BR locale, on all other sites show pt
export const usePreserveFrench = (region: LowerRegions) => {
  const [cookies, setCookie] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "fr") {
      if (!cookies["locale"]) {
        setTimeout(() => {
          i18n.changeLanguage("fr")
        })
      } else {
        const lang = cookies["locale"]
        if (lang === "fr" || lang === "fr-CA" || lang !== "en") {
          setTimeout(() => {
            i18n.changeLanguage("fr")
          })
          setCookie("locale", "fr", {
            path: "/",
          })
        } else {
          setTimeout(() => {
            i18n.changeLanguage("en")
          })
          setCookie("locale", "en", {
            path: "/",
          })
        }
      }
    } else if (region === "ca") {
      if (!cookies["locale"]) {
        setTimeout(() => {
          i18n.changeLanguage("fr-CA")
        })
      } else {
        const lang = cookies["locale"]
        if (lang === "fr" || lang === "fr-CA" || lang !== "en") {
          setTimeout(() => {
            i18n.changeLanguage("fr-CA")
          })
          setCookie("locale", "fr-CA", {
            path: "/",
          })
        } else {
          setTimeout(() => {
            i18n.changeLanguage("en")
          })
          setCookie("locale", "en", {
            path: "/",
          })
        }
      }
    } else {
      if (currentLanguage === "fr-CA" || cookies["locale"] === "fr-CA") {
        setTimeout(() => {
          i18n.changeLanguage("fr")
        })
        setCookie("locale", "fr", {
          path: "/",
        })
      }
    }
  }, [region])
}
